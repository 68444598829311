<script lang="ts">
  import { session } from "$app/stores";
  
  import Section from "$lib/components/Layout/Section.svelte";
  import { set_client_cookie } from "$lib/cookies";
  import { config } from "$lib/config";
  import { supabase } from "$lib/data/supabase";
  import { set_sentry_user } from "$lib/network/sentry";
  import { Route, route_for } from "$lib/route_list";

  let email_or_lnl_username: string;
  let password: string;
  let message: string;

  async function do_login() {
    const email = add_email_suffix(email_or_lnl_username);
    message = "Logging in...";
    const { session } = await supabase.auth.signIn({
      email,
      password,
    });

    if (session) {
      set_client_cookie(session);
      window.location.pathname = route_for(Route.runs);
      set_sentry_user();
    } else {
      // todo: show this message in ui
      console.warn("login failed");
      message = "Login failed";
    }
  }

  function add_email_suffix(incoming: string): string {
    const trimmed = incoming.trim();
    if (!trimmed.includes("@")) {
      return trimmed + config.ui.default_email_suffix;
    } else {
      return trimmed;
    }
  }

  function reset_form() {
    email_or_lnl_username = undefined;
    password = undefined;
  }

  async function forgot_password() {
    const email = add_email_suffix(email_or_lnl_username);
    const { data, error } = await supabase.auth.api.resetPasswordForEmail(
      email
    );
    if (!error) {
      message =
        "If there is a matching user, you will receive a password reset email ";
    } else {
      message =
        "Cannot request password reset - please wait 60 seconds and retry.";
    }
    console.log(">> sent forgot_password");
    reset_form();
  }

  async function magic_link() {
    const email = add_email_suffix(email_or_lnl_username);
    message =
      "If there is a matching user, you will receive a magic link email ";
    const { user, session, error } = await supabase.auth.signIn({ email });
    console.log(">> sent magic_link");
    reset_form();
  }

  function check_enter(e) {
    if ((e as KeyboardEvent).code === "Enter" && valid_form) {
      do_login();
    }
  }

  $: valid_form = Boolean(email_or_lnl_username && password);
</script>

<div class="base">
  <Section title="Login" single_pane={true}>
    {#if $session}
      <div class="p-3">
        <div class="block">
          You are already logged-in as
          <code>{$session.public_user?.email}</code>
        </div>
        <div class="block">
          View your <a href={route_for(Route.runs)}>Runs</a> or
          <a class="has-text-danger" href={route_for(Route.logout)}>Logout</a>
        </div>
      </div>
    {:else}
      <div class="p-3" on:keyup={check_enter}>
        <div class="field">
          <!-- svelte-ignore a11y-label-has-associated-control -->
          <label class="label">Email</label>

          <div class="control has-icons-left has-icons-right">
            <input
              name="email"
              class="input"
              class:is-danger={false}
              class:is-success={email_or_lnl_username}
              type="email"
              placeholder="email"
              autocomplete="email"
              bind:value={email_or_lnl_username}
            />
            <span class="icon is-small is-left">
              <i class="fas fa-user" />
            </span>
            <!-- <span class="icon is-small is-right">
              <i class="fas fa-exclamation-triangle" />
            </span> -->
          </div>
          <!-- <p class="help is-danger">This email is invalid</p> -->
        </div>

        <div class="field">
          <!-- svelte-ignore a11y-label-has-associated-control -->
          <label class="label">Password</label>
          <div class="control has-icons-left has-icons-right">
            <input
              name="password"
              class="input"
              class:is-success={password}
              type="password"
              autocomplete="password"
              placeholder="password"
              bind:value={password}
            />
            <span class="icon is-small is-left">
              <i class="fas fa-lock" />
            </span>
            <!-- <span class="icon is-small is-right">
              <i class="fas fa-exclamation-triangle" />
            </span> -->
          </div>
          <!-- <p class="help is-danger">This password is invalid</p> -->
        </div>

        <div class="field is-grouped">
          <div class="control">
            <button
              disabled={!valid_form}
              class="button is-link"
              on:click={do_login}>Login</button
            >
          </div>
        </div>

        <div class="">
          <button
            disabled={!email_or_lnl_username}
            class="button is-text"
            on:click={forgot_password}>I need to set a new password</button
          >
        </div>

        {#if message}
          <div class="notification is-warning">
            {message}
          </div>
        {/if}
      </div>
    {/if}
  </Section>
</div>

<style lang="scss">
  @import "../lib/css/_variables";
  .base {
    margin: 0 auto;
    padding: 1vh;
    background: $dark-background;
  }
</style>
